import React from "react";

const Skills = () => {
  return (
    <div id="skills" className="skills">
      <div className="container">
        <h3 className="title">Skills</h3>
        <div className="skills-info">
          <div className="col-md-6 bar-grids">
            <h6>
              Go, GraphQL, Ruby on Rails<span>80% </span>
            </h6>
            <div className="progress">
              <div
                className="progress-bar progress-bar-striped active"
                style={{ width: "95%" }}
              ></div>
            </div>
            <h6>
              SpringBoot, Java, JavaFX, JavaEE<span>90% </span>
            </h6>
            <div className="progress">
              <div
                className="progress-bar progress-bar-striped active"
                style={{ width: "90%" }}
              ></div>
            </div>
            <h6>
              Web Design <span> 80% </span>
            </h6>
            <div className="progress">
              <div
                className="progress-bar progress-bar-striped active"
                style={{ width: "80%" }}
              ></div>
            </div>
            <h6>
              Software Development <span> 85% </span>
            </h6>
            <div className="progress">
              <div
                className="progress-bar progress-bar-striped active"
                style={{ width: "85%" }}
              ></div>
            </div>
            <h6>
              Unix Operating Systen &amp; Shell Programming <span> 85% </span>
            </h6>
            <div className="progress">
              <div
                className="progress-bar progress-bar-striped active"
                style={{ width: "85%" }}
              ></div>
            </div>
            <h6>
              C, C++, Python <span> 75% </span>
            </h6>
            <div className="progress">
              <div
                className="progress-bar progress-bar-striped active"
                style={{ width: "75%" }}
              ></div>
            </div>
          </div>
          <div className="col-md-6 bar-grids">
            <h6>
              PROJECT MANAGEMENT <span> 85% </span>
            </h6>
            <div className="progress">
              <div
                className="progress-bar progress-bar-striped active"
                style={{ width: "85%" }}
              ></div>
            </div>
            <h6>
              Git VCS<span> 90% </span>
            </h6>
            <div className="progress">
              <div
                className="progress-bar progress-bar-striped active"
                style={{ width: "90%" }}
              ></div>
            </div>
            <h6>
              Android Development <span> 95% </span>
            </h6>
            <div className="progress">
              <div
                className="progress-bar progress-bar-striped active"
                style={{ width: "80%" }}
              ></div>
            </div>
            <h6>
              HTML, CSS, JavaScript, jQuery<span> 85% </span>
            </h6>
            <div className="progress">
              <div
                className="progress-bar progress-bar-striped active"
                style={{ width: "85%" }}
              ></div>
            </div>
            <h6>
              PL/SQL, MySQL, SQLite <span> 75% </span>
            </h6>
            <div className="progress">
              <div
                className="progress-bar progress-bar-striped active"
                style={{ width: "75%" }}
              ></div>
            </div>
            <h6>
              JSON, AJAX <span> 80% </span>
            </h6>
            <div className="progress">
              <div
                className="progress-bar progress-bar-striped active"
                style={{ width: "80%" }}
              ></div>
            </div>
          </div>
          <div className="clearfix"> </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
