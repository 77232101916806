import React from 'react';
import img_profile from "./profile.jpg";

const Banner = () =>{
    return(
        <div id="home" className="banner">
            <div className="banner-info">
                <div className="container">
                <div className="col-md-4 header-left">
                    <img src={img_profile} alt=""/>
                </div>
                <div className="col-md-8 header-right">
                    <h2>Hello</h2>
                    <h1>I'm Asif Mohammad Mollah</h1>
                    <h6>Sr. Software Engineer, Indian Author</h6>
                    <ul className="address">
                    <li>
                        <ul className="address-text">
                        <li><b>ADDRESS </b></li>
                        <li>West Bengal, India.</li>
                        </ul>
                    </li>
                    {/* <li>
                        <ul className="address-text">
                        <li><b>E-MAIL </b></li>
                        <li><a href="mailto:admin@mrasif.in">admin@mrasif.in</a></li>
                        </ul>
                    </li> */}
                    <li>
                        <ul className="address-text">
                        <li><b>WEBSITE </b></li>
                        <li><a href="http://www.mrasif.in">www.mrasif.in</a></li>
                        </ul>
                    </li>
                    </ul>
                </div>
                <div className="clearfix"> </div>
                </div>
            </div>
            </div>
    );
}

export default Banner;
