import React from "react";

const WorkCard=({isEven, fromDate, toDate, company, description})=>{
    let left = (
        <div className="work-info">
                <div className="col-md-6 work-right work-right2">
                    <h4>{fromDate} - {toDate} </h4>
                </div>
                <div className="col-md-6 work-left work-left2">
                    <h5>{company}<span className="glyphicon glyphicon-briefcase"> </span></h5>
                    <p>{description}</p>
                </div>
            <div className="clearfix"> </div>
        </div>
    );

    let right = (
        <div className="work-info">      
            <div className="col-md-6 work-left">
                <h4>{fromDate} - {toDate}</h4>
            </div>
            <div className="col-md-6 work-right">
                <h5><span className="glyphicon glyphicon-briefcase"> </span>{company}</h5>
                <p>{description}</p>
            </div>
            <div className="clearfix"> </div>
        </div>
    );

    
    return(
        isEven?right:left
    );
}

export default WorkCard;