import React, { Component } from "react";
import Banner from "../../components/banner/Banner";
import Nav from "../../components/nav/Nav";
import About from "../../components/about/About";
import Works from "../../components/works/Works";
import Education from "../../components/education/Education";
import Skills from "../../components/skills/Skills";
import Bookself from "../../components/bookshelf/Bookshelf";
// import MyProjects from "../../components/myProjects/MyProjects";
import Connections from "../../components/connections/Connections";
import Footer from "../../components/footer/footer";

class Home extends Component {
  render() {
    return (
      <div>
        <Banner />
        <Nav />
        <About />
        <Works />
        <Education />
        <Skills />
        <Bookself />
        {/* <MyProjects /> */}
        <Connections />
        <Footer />
      </div>
    );
  }
}
export default Home;
