import React from "react";

import linkedin from "./images/linkedin.png";
import github from "./images/github.png";
import stackoverflow from "./images/stackoverflow.png";
import goodreads from "./images/goodreads.png"
import "./style.css";

const Connections = () => {
  return (
    <div className="welcome connections" id="connections">
      <div className="container">
        <h3 className="title">Connections</h3>
        <div className="connections-row">
          <div className="col-md-12 connections-left">
            <img src={linkedin} onClick={()=>{
              window.open("https://www.linkedin.com/in/asifmohammadmollah", "_blank");
            }} width="125" alt="" />
            <img src={github} onClick={()=>{
              window.open("https://github.com/mrasif", "_blank");
            }} width="130" alt="" />
            <img src={stackoverflow} onClick={()=>{
              window.open("https://stackoverflow.com/users/5111626/asif-mohammad-mollah", "_blank");
            }} width="125" alt="" />
            <img src={goodreads} onClick={()=>{
              window.open("https://www.goodreads.com/author/show/22907710.Asif_Mohammad_Mollah", "_blank");
            }} width="125" alt="" />
          </div>
          <div className="clearfix"></div>
        </div>
      </div>
    </div>
  );
};

export default Connections;
