import React from 'react';

const About=()=>{
    return(
        <div id="about" className="about">
            <div className="container">
                <h3 className="title"> About Me</h3>
                <div className="col-md-8 about-left">
                <p>&nbsp;&nbsp;&nbsp;&nbsp;I have work on Software & Web Development field since the year 2009. Then I was a Web Developer of a private group in Berhampore. After that I have done so many project on Web and Software Development for Private and Government Sector as a Freelancer.</p><br/>

                <p>&nbsp;&nbsp;&nbsp;&nbsp;After that I joined Five Csters Pvt. Ltd. company as a Software Developer cum Technical H.R. Then I had work with National IT And Cyber Security Research Association as a Technical Instructor and Ardent Computech Pvt. Ltd. as a Trainer cum Software Developer. After that We formed a startup named Futurebuzz Consultant LLP.<br/>
                    Now I am Sr. Software Engineer at AppKnox.</p>
                </div>
                <div className="col-md-4 about-right">
                <ul>
                    <h5>Awards</h5>
                    <li><span className="glyphicon glyphicon-menu-right"></span> Got Star Performer award in InGreens Business Meet 2018-19.</li>
                    <li><span className="glyphicon glyphicon-menu-right"></span> Got award in B.E.S. Education Fair for Making a Robot.</li>
                    <li><span className="glyphicon glyphicon-menu-right"></span> Got award in Tech. Fest at GCELT for developing a Website.</li>
                    <li><span className="glyphicon glyphicon-menu-right"></span> Got 371 Rank in CodeVita Global Coding competition.</li>
                </ul>
                <div className="clearfix"> </div>
                </div>
                <div className="clearfix"> </div>
            </div>
            </div>
    );
}

export default About;