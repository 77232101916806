import React from "react";

import img1 from "./images/internet.jpg";
import img2 from "./images/protect-yourself-from-hackers.jpg";

const Bookshelf = () => {
  return (
    <div id="bookshelf" className="portfolio">
      <div className="container">
        <h3 className="title wow zoomInLeft animated" data-wow-delay=".5s">
          Bookshelf
        </h3>
        <div className="book-card">
          <div className="book-cover">
            <img src={img1} alt="" className="img-responsive" />
          </div>
          <div className="book-info">
            <h2>Internet: Fundamentals of Internet</h2>
            <span className="sub-title">Author: Asif Mohammad Mollah, Language: English</span>
            <p>
              Now a day's internet has become an important part of our life. We are very much dependent on internet for many purposes as we use internet for social network, we also use internet for educational purposes and communicating with friends, families etc. We can also use internet in defense etc.
              The initial idea is credited as being Leonard Klein rock's after he published his first paper entitled "Information Flow in Large Communication Nets" on May 31, 1961.
              Purpose of this book is to understand how Internet works and what is the basic.
            </p>
            <h3>Buy Book on:</h3>
            <div className="link-box">
              <a href="https://www.amazon.com/Internet-Fundamentals-Asif-Mohammad-Mollah-ebook/dp/B0BB1564VC/ref=sr_1_4?crid=3NY1WDI7J4WMD&keywords=Internet%3A+fundamentals+of+internet&qid=1661900718&sprefix=internet+fundamentals+of+internet%2Caps%2C300&sr=8-4" rel="noopener noreferrer" target="_blank">
                Amazon.com
              </a>
              <a href="https://www.amazon.in/Internet-Fundamentals-Asif-Mohammad-Mollah-ebook/dp/B0BB1564VC/ref=sr_1_1?crid=3IJGN012FXT41&keywords=internet%3A+fundamentals+of+internet&qid=1661900499&sprefix=%2Caps%2C183&sr=8-1" rel="noopener noreferrer" target="_blank">
                Amazon.in
              </a>
              <a href="https://www.flipkart.com/internet-fundamentals/p/itm1ff1d69044f97?pid=9798888051184&lid=LSTBOK979888805118404AWD2" rel="noopener noreferrer" target="_blank">
                Flipkart
              </a>
              <a href="https://notionpress.com/read/internet" rel="noopener noreferrer" target="_blank">
                Notionpress
              </a>
            </div>
          </div>
        </div>
        
        <div className="book-card">
          <div className="book-cover">
            <img src={img2} alt="" className="img-responsive" />
          </div>
          <div className="book-info">
            <h2>Protect Yourself from Hackers</h2>
            <span className="sub-title">Author: Asif Mohammad Mollah, Language: English</span>
            <p>
              Ethical hacking is the practice of using hacking techniques to identify vulnerabilities and weaknesses in computer systems, 
              networks, and applications, with the goal of improving their security. Unlike malicious hacking, ethical hacking is conducted 
              with the explicit permission and knowledge of the system owner, and is intended to identify and address security issues before 
              they can be exploited by attackers. Ethical hacking is a crucial component of modern cybersecurity, and is used by organizations 
              around the world to protect their systems and data from cyber threats.
            </p>
            <h3>Buy Book on:</h3>
            <div className="link-box">
              <a href="https://www.amazon.com/dp/B0C3DNGQ1J" rel="noopener noreferrer" target="_blank">
                Amazon.com
              </a>
              <a href="https://www.amazon.in/dp/B0C3DNGQ1J" rel="noopener noreferrer" target="_blank">
                Amazon.in
              </a>
              {/* <a href="#" rel="noopener noreferrer" target="_blank">
                Flipkart
              </a>
              <a href="#" rel="noopener noreferrer" target="_blank">
                Notionpress
              </a> */}
            </div>
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default Bookshelf;
