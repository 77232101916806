import React from 'react';
import WorkCard from '../../widgets/WorkCard';
import works from '../../dao/works'

const Works=()=>{

    let filterredData=works.filter(({isHidden})=>{return !isHidden})

    return(
        <div id="work" className="work">
            <div className="container">
                <h3 className="title">Work Experience</h3>
                {
                    filterredData.map((item, index) => (
                        <WorkCard
                            isEven={index%2} 
                            fromDate={item.fromDate} 
                            toDate={item.toDate} 
                            company={item.company}
                            description={item.description}
                            display={item.isHidden} />
                    ))
                }

            </div>
            </div>
    );
}

export default Works;
