import React from "react";

const Education = () => {
  return (
    <div id="education" className="education">
      <div className="container">
        <h3 className="title">Education</h3>
        <div className="work-info">
          <div className="col-md-6 work-left">
            <h4>B.Tech in C.S.E. - 2017</h4>
          </div>
          <div className="col-md-6 work-right">
            <h5>
              <span className="glyphicon glyphicon-education"> </span> MAKAUT
              (WBUT)
            </h5>
            <p>
              Completed graduation in Computer Science and Engineering at
              Government College Of Engineering and Leather Technology under
              Maulana Abul Kalam Azad University Of Technology (Formaly known as
              West Bengal University Of Technology).
            </p>
          </div>
          <div className="clearfix"> </div>
        </div>
        <div className="work-info">
          <div className="col-md-6 work-right work-right2">
            <h4>Diploma in C.S.T. - 2014</h4>
          </div>
          <div className="col-md-6 work-left work-left2">
            <h5>
              {" "}
              West Bengal State Council Of Technical Education{" "}
              <span className="glyphicon glyphicon-education"></span>
            </h5>
            <p>
              Completed Diploma in Computer Science and Technology at Dumkal
              Polytechnic under West Bengal State Council Of Technical
              Education.
            </p>
          </div>
          <div className="clearfix"> </div>
        </div>
        <div className="work-info">
          <div className="col-md-6 work-left">
            <h4>
              Madhyamik (10<sup>th</sup>) - 2009
            </h4>
          </div>
          <div className="col-md-6 work-right">
            <h5>
              <span className="glyphicon glyphicon-education"> </span> West
              Bengal Board Of Secondary Education{" "}
            </h5>
            <p>
              Completed 10<sup>th</sup> Standard at Lalnagar High School (H.S.)
              under West Bengal Board Of Secondary Education.
            </p>
          </div>
          <div className="clearfix"> </div>
        </div>
      </div>
    </div>
  );
};

export default Education;
