import React, { useState } from "react";

const Nav = () => {
  let [toggle, SetToggle] = useState("collapse");
  return (
    <div className="top-nav wow">
      <div className="container">
        <div className="navbar-header logo">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
            onClick={() => {
              toggle === "collapse"
                ? SetToggle("collapsed")
                : SetToggle("collapse");
            }}
          >
            Menu
          </button>
        </div>
        <div
          className={`${toggle} navbar-collapse`}
          id="bs-example-navbar-collapse-1"
        >
          <div className="menu">
            <ul className="nav navbar">
              <li>
                <a href="#about" className="scroll">
                  About
                </a>
              </li>
              <li>
                <a href="#work" className="scroll">
                  Experience
                </a>
              </li>
              <li>
                <a href="#education" className="scroll">
                  Education
                </a>
              </li>
              <li>
                <a href="#skills" className="scroll">
                  Skills
                </a>
              </li>
              <li>
                <a href="#bookshelf" className="scroll">
                  Bookshelf
                </a>
              </li>
              {/* <li>
                <a href="#projects" className="scroll">
                  My Projects
                </a>
              </li> */}
              <li>
                <a href="#connections" className="scroll">
                  Connections
                </a>
              </li>
            </ul>
            <div className="clearfix"> </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nav;
